import { OrbitDatabase } from '../OrbitDatabase';
import { Store } from './Store'

export abstract class DocumentStore<TEntity> extends Store {

    constructor(private storeName: string,
        private storeOptionsConfigurer: (options: any) => any,
        orbit: OrbitDatabase) {
        super(orbit)

        this.orbit.db.then(db => {
            this.defaultStoreOptions = {
                accessController: {
                    write: [db.identity.id]
                }
            }

            this.store = new Promise((resolve, reject) =>{
                db.docstore(this.storeName, this.storeOptionsConfigurer(this.defaultStoreOptions))
                .then((store: any)=>{
                    store.load().then(() => resolve(store))
                }).catch(reject);
            });
        });
    }

    // Need to change the "load"...

    public async getAll(): Promise<TEntity[]> {
        const store = await this.store;
        return store.get('');
    }

    public async getByIndex(index: string): Promise<TEntity> {
        const store = await this.store;
        return store.get(index)[0];
    }

    public async query(query: (entity: TEntity) => boolean): Promise<TEntity[]> {
        const store = await this.store;
        return store.query(query);
    }

    public async put(entity: TEntity): Promise<void> {
        const store = await this.store;
        await store.put(entity);
    }

    public async deleteByIndex(indexValue: string): Promise<void> {
        const store = await this.store;
        await store.del(indexValue);
    }
}