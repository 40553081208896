export class Dimension {
    constructor(
        public width: number,
        public height: number) { }
}

export class WindowDimension {

    public getWidth(): number { return window.innerWidth; }
    public getHeight(): number { return window.innerHeight; }

    dimensions: Dimension = {
        width: this.getWidth(),
        height: this.getHeight()
    }
}