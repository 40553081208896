import { OrbitDatabase } from '../OrbitDatabase'

export abstract class Store {
    protected store: Promise<any>;
    protected defaultStoreOptions: any;

    constructor(protected orbit: OrbitDatabase) { }

    public async load(): Promise<void> {
        const store = await this.store;
        await store.load();
    }

    public async printIdentity(): Promise<string> {
        const store = await this.store;
        const identity = store.identity;
        console.log(identity.toJSON());
        
        return identity.publicKey;
    }

    public async printAddr(): Promise<string> {
        const store = await this.store;
        const identity = store.identity
        const db = await this.orbit.db
        const dbAddress =await db.determineAddress('weathers', 'docstore', {
            accessController: {
              write: [
                identity.publicKey
            ]}
          });

        console.log(dbAddress);
        return dbAddress.root;
    }
}