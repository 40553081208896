import * as bootstrap from '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import { Web3Authentication } from "./Web3Authentication";
import { WindowDimension } from "./WindowDimension"
import { OrbitDatabase } from './OrbitDatabase'
import { WeatherStore } from './Stores/WeatherStore'

declare global {
  interface Window { 
    InterPlanetaryApp: Self; 
  }
}

const APPLICATION_BOOT_FUNCTIONS = {
  GET_CLIENT_ID: "GetClientId",
  GET_REDIRECT_URL: "GetRedirectUrl",
} as const;

class Self {
  orbit: OrbitDatabase = new OrbitDatabase();
  windowDimension: WindowDimension = new WindowDimension();

  authentication: Web3Authentication;
  weatherStore: WeatherStore = new WeatherStore(this.orbit);

  public async boot(applicationBootDotNetHelper: any): Promise<void> {
    const clientId: string = await applicationBootDotNetHelper.invokeMethodAsync(APPLICATION_BOOT_FUNCTIONS.GET_CLIENT_ID);
    const redirectUrl: string = await  applicationBootDotNetHelper.invokeMethodAsync(APPLICATION_BOOT_FUNCTIONS.GET_REDIRECT_URL);

    this.authentication = new Web3Authentication(clientId, redirectUrl);
  }

  public async hide(elementId: string): Promise<void> {
    var myCollapse = document.getElementById(elementId);
    var bsCollapse = new bootstrap.Collapse(myCollapse, {
      toggle: false
    });
    bsCollapse.hide();
  }
}

window.InterPlanetaryApp = window.InterPlanetaryApp || new Self();
