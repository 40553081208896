module.exports={
  "sha1": {
    "securityStrength": 128,
    "outlen": 160,
    "seedlen": 440
  },
  "sha224": {
    "securityStrength": 192,
    "outlen": 224,
    "seedlen": 440
  },
  "sha256": {
    "securityStrength": 256,
    "outlen": 256,
    "seedlen": 440
  },
  "sha384": {
    "securityStrength": 256,
    "outlen": 384,
    "seedlen": 888
  },
  "sha512": {
    "securityStrength": 256,
    "outlen": 512,
    "seedlen": 888
  }
}
