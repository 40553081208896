import { OrbitDatabase } from '../OrbitDatabase'
import { DocumentStore } from './DocumentStore'

export interface WeatherForecast {
    date: String;
    temperatureC: number;
    summary: string;
}

export class WeatherStore extends DocumentStore<WeatherForecast> {

    constructor(orbit: OrbitDatabase) {
        super(
            "weathers", 
            () => {
                return {
                    ...this.defaultStoreOptions,
                    indexBy: 'date',
                };
            }, 
            orbit
        );
    }
}