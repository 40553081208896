import * as Ipfs from '../node_modules/ipfs/index.min.js'
const OrbitDB = require('orbit-db');

export class OrbitDatabase {
    private ipfsOptions: any = {
        preload: { enabled: false },
        // repo: './ipfs', only required for nodejs app
        EXPERIMENTAL: { pubsub: true },
        config: {
            Bootstrap: [],
            Addresses: { Swarm: [] }
        }
    }

    public db: Promise<any>;

    constructor() {
        this.db = new Promise((resolve, reject) => {
            Ipfs.create(this.ipfsOptions)
            .then((ipfsResult: any) => {
                OrbitDB.createInstance(ipfsResult)
                .then((orbitdbResult: any) => {
                    console.log("OMG ANOTHER INSTANCE CREATED");
                    resolve(orbitdbResult);
                }).catch(reject);
            }).catch(reject);
        });
    }

    public onReady(dotNetHelper: any, methodName: string): void {
        this.db.then(async () => {
            await dotNetHelper.invokeMethodAsync(methodName);
        });
    }

}