class ReplicationInfo {
  constructor () {
    this.progress = 0
    this.max = 0
  }

  reset () {
    this.progress = 0
    this.max = 0
  }
}

module.exports = ReplicationInfo
