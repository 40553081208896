import OpenLogin from "@toruslabs/openlogin";
import { LoginParams, LOGIN_PROVIDER, OpenloginUserInfo, OPENLOGIN_NETWORK, OpenLoginOptions } from "@toruslabs/openlogin";

export class Web3Authentication {
  openlogin: OpenLogin;
  ready: Promise<void>;

  constructor(private clientId: string, private redirectUrl: string) {
    const options: OpenLoginOptions = {
      clientId: this.clientId,
      network: OPENLOGIN_NETWORK.TESTNET,
      redirectUrl: this.redirectUrl,
      uxMode: "redirect"
    };
    this.openlogin = new OpenLogin(options);

    this.ready = new Promise((resolve, reject) => {
      this.openlogin.init()
        .then(() => resolve(undefined))
        .catch(reject);
    });
  }

  public async login(email: string): Promise<string> {
    const params: LoginParams = {
      extraLoginOptions: {
        login_hint: email,
      },
      loginProvider: LOGIN_PROVIDER.EMAIL_PASSWORDLESS
    };
    await this.ready;
    if (this.openlogin.privKey) {
      return;
    }
    await this.openlogin.login(params);
  }

  public async logout(): Promise<void> {
    await this.ready;
    await this.openlogin.logout();
  }

  public async getUserInfo(): Promise<Web3UserInfo> {
    await this.ready;
    if(!this.openlogin.privKey){
      return;
    }

    var openLoginUserInfo: OpenloginUserInfo = await this.openlogin.getUserInfo();
    var userInfo: Web3UserInfo = openLoginUserInfo as Web3UserInfo;

    userInfo.privateKey = this.openlogin.privKey;

    return userInfo;
  }
}


declare type Web3UserInfo = OpenloginUserInfo & { 
  privateKey: String 
}